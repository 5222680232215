<template>
  <div id="nav" class="nav">
    <!--router-link to="/" class="brand">Real World Events</router-link-->
    <nav>
      <!--div class="nav-item"><a href="#que-es">¿Qué es?</a></div>
      <div class="nav-item"><a href="#como-funciona">Cómo Funciona</a></div>
      <div class="nav-item"><a href="#productos">Nuestros Productos</a></div-->
      <div class="nav-item contact-item"><a href="#contactanos">Contacto</a></div>
      <!--router-link :to="{ name: 'gallery' }">Galería</router-link-->
    </nav>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  width: 100%;
  nav {
    width: 100%;
  }
}
.nav > .brand {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 1.5em;
  color: #39b982;
  text-decoration: none;
}
.nav .nav-item {
  box-sizing: border-box;
  margin: 0 5px;
  color: rgba(0, 0, 0, 0.5);
  text-decoration: none;
    display: inline-block;
  font-weight: bold;
  color: #ffffff;
  font-family: Montserrat-Bold;
  font-size: 14px;
  color: #FFFFFF;
  letter-spacing: 2.8px;
  text-align: center;
  text-transform: uppercase;
  text-align: right;
  padding: 15px;
  a {
    color: #ffffff;
    text-decoration: none;
  }
  &.contact-item {
    background: #00E190;
  }
}
.nav .nav-item.router-link-exact-active {
  color: #39b982;
  border-bottom: solid 2px #39b982;
}
@media all and (max-width: 1366px) {
  .nav .nav-item {
    padding: 10px;
    font-size: 0.8rem;
  }
}
@media all and (max-width: 1280px) {
  .nav .nav-item {
    padding: 10px;
    font-size: 0.6rem;
  }
}
@media all and (max-width: 1024px) {
  .nav .nav-item {
    display: none;
  }
}
</style>
