<template>
  <div class="home">    
    <header>
      <div class="logo"><img alt="Vue logo" src="../assets/logo-paseguros-auto-invert.png" /></div>
      <NavBarAutos />
    </header>
    <IntroAutos />
    <Autos />
    <Contactanos />
    <Footer />
    <!--Home msg="Welcome to Your Vue.js App" /-->
  </div>
</template>

<script>
// @ is an alias to /src
import IntroAutos from "@/components/IntroAutos.vue";
import Autos from "@/components/Autos.vue";
import Footer from "@/components/Footer.vue";
import NavBarAutos from "@/components/NavBarAutos.vue";
import Contactanos from '../components/Contactanos.vue';

export default {
  /*name: "Home",*/
  components: {
    IntroAutos,
    NavBarAutos,
    Autos,
    Footer,
    Contactanos
  }
};
</script>

<style lang='scss' scoped>
  .logo {
    padding-top: 20px;
    img {
      height: 30px;
    }
  }

</style>