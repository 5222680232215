  <template>
  <div class="home-section productores-section" id='productores'>
    <div class="productores-info">
        <h2>COTIZÁ EL SEGURO DE TU AUTO</h2>
    </div>

    <div class="cotizacion" id="cotizacion-step-1">        
        <p>
            Estas compañías están cerca de tu ubicación, con cuales te gustaría cotizar el seguro de tu auto?
        </p>
        <div  v-for="company in companies" :key="company.id" :id="'company-'+company.id" :company="company.name" class='company-item select-item' @click="toggleCompanySelected(company.id)">
            <img :src="company.url_logo" alt="">
        </div>
        <div class="seleccion-marca-auto button-next" v-if="showSelectAuto" @click="changeState(1)">
            ¡Avanzar y seleccionar la marca de tu vehículo!
        </div>
    </div>
    <div class="cotizacion hide" id="cotizacion-step-2">
        <p>
            Elegí la Marca de tu vehículo!
        </p>
        <div  v-for="brand in brands" :key="brand.id" :id="'brand-'+brand.id" :company="brand.name" class='brand-item select-item' @click="infoautoGetModel(brand.id)">
            <img :src="brand.logo_url" alt="">
        </div>
        <!--div class="seleccion-marca-auto" v-if="showSelectModelo">
            ¡Avanzar y seleccionar el modelo de tu vehículo!
        </div-->
    </div>
    <div class="cotizacion hide" id="cotizacion-step-3">
        <p>
            Elegí el Modelo, podes usar el filtro acá abajo para encontrarlo más rápido 😎 !
        </p>
        <div class="filter filter-model">
          <input class="filter-input input" placeholder="Empezá a escribir el modelo de tu auto" v-model="model.name"> 
        </div>
        
        <div  v-for="model in autocomplete" :key="model.id" :id="'model-'+model.id" :company="model.name" class='model-item select-item' @click="infoautoGetVersions(model.id)">
            {{model.name}}
        </div>
        <!--div class="seleccion-marca-auto" v-if="showSelectModelo">
            ¡Avanzar y seleccionar el modelo de tu vehículo!
        </div-->
    </div>
    <div class="cotizacion hide" id="cotizacion-step-4">
        <p>
            Elegí el Año de tu vehículo!
        </p>
        <div  v-for="year in years" :key="year" class='year-item select-item' @click="toggleVersionSelected(year)">
            {{year}}
        </div>        
    </div>
    <div class="cotizacion hide" id="cotizacion-step-5">
        <p>
            Elegí la versión!
        </p>
        <div  v-for="version in versionsFiltered" :key="version.codia" :id="'version-'+version.codia"  class='version-item select-item' @click="getPersonalData(version)">
            {{version.name}}
        </div>        
    </div>
    <div class="cotizacion hide" id="cotizacion-step-6">
        <p>
            Por último, completa tus datos!
        </p>
        <div class="form-group">
            <div class="form-item">
                <label for="nombre">Nombre</label>
                <input type="text" name="nombre" id='nombre' validate validation-type='text' validation-error='Ingrese su nombre' placeholder="Nombre">
            </div>
            <div class="form-item">
                <label for="apellido">Apellido</label>
                <input type="text" name="apellido" id='apellido' validate validation-type='text' validation-error='Ingrese su apellido' placeholder="Apellido">
            </div>
            <div class="form-item">
                <label for="email">Email</label>
                <input type="text" name="email" id='email' validate validation-type='email' validation-error='Ingrese un mail válido'  placeholder="Email">
            </div>
            <div class="form-item">
                <label for="dni">DNI</label>
                <input type="text" id='dni' name='dni' validate validation-type='dni' validation-error='Ingrese un DNI válido.'  placeholder="DNI">
            </div>
            <div class="form-item">
                <label for="nombre">Celular</label>
                <input type="text" id='celular' name='celular' validate validation-type='celular' validation-error='Ingrese un celular correcto, sin 0 ni 15'  placeholder="Celular">
            </div>
            <!--div class="form-item modo-de-pago">
                <label for="apellido">Forma Pago</label>
                <select id="modo-de-pago" name="modo-de-pago">
                    <option disabled>Forma de Pago</option>
                    <option value="01" disabled type="efectivo" class="hide">EFECTIVO</option>
                    <option value="02" disabled type="tarjeta" class="hide">TARJETA DE CRÉDITO</option>
                    <option value="03" disabled type="debito" class="hide">DÉBITO DIRECTO</option>
                </select>
            </div-->
            <div class="form-item">
                <label for="date">Fecha de Nac.</label>
                <input type="text" name="fecha_nacimiento" id='fecha_nacimiento' validate validation-type='text' validation-error='Ingrese su fecha de nacimiento' placeholder="dd/mm/aaaa">
            </div>
            
            <div class="form-item">
                <label for="provincia">Provincia</label>
                <select id="provincia" name="provincia" @change="proCloudGetLocalidades($event)">
                    <option value='-'>Seleccione</option>
                    <option value="02">BUENOS AIRES</option><option value="23">CAPITAL FEDERAL</option><option value="20">CATAMARCA</option><option value="08">CHACO</option><option value="11">CHUBUT</option><option value="03">CORDOBA</option><option value="15">CORRIENTES</option><option value="05">ENTRE RIOS</option><option value="06">FORMOSA</option><option value="10">JUJUY</option><option value="16">LA PAMPA</option><option value="18">LA RIOJA</option><option value="13">MENDOZA</option><option value="09">MISIONES</option><option value="17">NEUQUEN</option><option value="14">RIO NEGRO</option><option value="01">SALTA</option><option value="21">SAN JUAN</option><option value="12">SAN LUIS</option><option value="24">SANTA CRUZ</option><option value="19">SANTA FE</option><option value="04">SANTIAGO DEL ESTERO</option><option value="22">TIERRA DE FUEGO</option><option value="07">TUCUMAN</option>
                </select>
            </div>
            <div class="form-item">
                <label for="localidad">Localidad</label>
                <select id="localidad" name="localidad">
                    <option value='-'>Seleccione</option>
                    <option v-for="item in localidades" :value="item.id" :key="item.id"> 
                      {{ item.descripcion }}
                    </option>
                </select>
            </div>
            <div class="seleccion-marca-auto button-next" @click="getQuotes()">
              ¡Cotizar ahora en las compañías seleccionadas!
          </div>
        </div>
    </div>
    <div class="cotizacion hide" id="cotizacion-step-7">
        <p>
            Estas son las coberturas que tenemos para vos!
        </p>

        <div  v-for="quote in quotes" :key="quote.company.id" :id="'quote-'+quote.company.id"  class='version-item select-item company-quote-item'>
            <div class="quote-company"><img :src="quote.company.logo" width="100%"></div>
            <div  v-for="result in quote.quotes" :key="result.quoteCode" :id="'quote-'+result.quoteCode"  class='quote-item' @click="getPersonalData(version)">
              <div class="cobertura-familia" :class="'cobertura-familia-'+result.quoteFamily" >{{result.quoteFamily}}</div>
              <div class="cobertura-nombre">{{result.quoteName}}</div>
              <div class="cobertura-costo">${{result.premio.mensual}}</div>
            </div>
        </div>
    </div>

    <!--div  v-for="productor in productores.productores"
      :key="productor.id"
      :productor="productor">
      {{ productor.fantasy_name }}
      {{ productor.location.address }}</div>-->
        <div id="productores-map">
        <!--h2>Search and add a pin</h2>
        <label>
            <gmap-autocomplete
            @place_changed="setPlace">
            </gmap-autocomplete>
            <button @click="addMarker">Add</button>
        </label-->
        <gmap-map :center="center" :zoom="14" style="width:100%;  height: 400px;">
            <gmap-marker :key="index" v-for="(m, index) in markers" :position="m.position" :icon="{ url: m.icon}" @click="toggleInfoWindow(m,index)"></gmap-marker>

            <gmap-info-window
            :options="infoOptions"
            :position="infoWindowPos"
            :opened="infoWinOpen"
            @closeclick="infoWinOpen=false">
            <div v-html="infoContent"></div>
          </gmap-info-window>
        </gmap-map>    
        </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import store from "@/store/index";
//import gsap from "gsap";

function getProductores(routeTo, next) {
  const currentPage = parseInt(routeTo.query.page) || 1;
  store
    .dispatch("productores/fetchProductores", {
      page: currentPage
    })
    .then(() => {
      routeTo.params.page = currentPage;
      next();
    });
}

export default {
  props: {
  },
  components: {    
  },
  beforeRouteEnter(routeTo, routeFrom, next) {
    getProductores(routeTo, next);
  },
  beforeRouteUpdate(routeTo, routeFrom, next) {
    getProductores(routeTo, next);
  },
  computed: {   
    autocomplete() {
      //console.log(this.model.name);
      //console.log(this.models);
      //return this.models.filter(v => v.name == this.model.name)
      return this.models.filter(o => 
        o.name.toLowerCase().includes(this.model.name.toLowerCase()));
      
    },
    ...mapState(["productores", "infoauto", "procloud"])
  },
  name: "Autos",
  data() {
      
        
    return {
      // default to Montreal to keep it simple
      // change this to whatever makes sense
      center: { lat: -34.60085926302077, lng: -58.27508760231044 },
      markers: [],
      places: [],
      localidades: [],
      companies: [],
      brands: [],
      brandId: null,
      models: [],
      model: {name:''},
      versions: [],
      codia: null,
      versionsFiltered: [],
      years: [],
      quotes: [],
      year: null,
      currentPlace: null,
      icons: [],
      showSelectAuto: false,
      showCotizar: false,
      showQuotes: false,
      infoContent: '',
      personalData: {},
        infoWindowPos: {
          lat: 0,
          lng: 0
        },
        infoWinOpen: false,
        currentMidx: null,
        //optional: offset infowindow so it visually sits nicely on top of our marker
        infoOptions: {
          pixelOffset: {
            width: 0,
            height: -35
          }
        }
    };
  },
  mounted() {
    
    this.geolocate();

    this.infoautoGetBrand();
    
   const iconBase = "https://developers.google.com/maps/documentation/javascript/examples/full/images/";

    this.icons = {
      parking: {
        icon: iconBase + "parking_lot_maps.png",
      },
      library: {
        icon: iconBase + "library_maps.png",
      },
      info: {
        icon: iconBase + "info-i_maps.png",
      },
      productor: {
        icon: 'http://paseguros.com.ar/img/marker-productores-50px.png'
      }
    };
    /*const marker1 = {
          lat: -34.60085926302077,
          lng: -58.37629425813681
        };
    const marker2 = {
        lat: -34.71691056018943,
        lng:  -58.27508760231044
    };*/

    navigator.geolocation.getCurrentPosition(function(position){
        console.log(position);
    });
    for (let index = 0; index < this.productores.productores.length; index++) {
      const element = this.productores.productores[index];
      //console.log(element.location.geolocation.lat);
      if (element.location.geolocation.lat != null) {
        console.log(this.icons['library'].icon);
      //console.log(this.productores.productores[index].location.geolocation );
        this.markers.push({ 
          position: {lat: Number(element.location.geolocation.lat), lng:  Number(element.location.geolocation.lng)},
          icon: this.icons['productor'].icon,
          name: element.fantasy_name,
          description: element.phone+ "<br>"+element.mail,
          logo: element.logo,
          active_codes: element.active_codes
          });
            if (element.active_codes != null) {
                for (let j = 0; j < element.active_codes.length; j++) {
                    const active_code = element.active_codes[j];
                    var addCompany = true;
                    for (let i = 0; i < this.companies.length; i++) {                
                        if (active_code.company.id ==  this.companies[i].id) {
                            addCompany = false;
                        }
                    }                
                    if (addCompany) {
                        this.companies.push(active_code.company);              
                    }
                }      
            }            
      }
      
    }
    
    //this.markers.push({ position: marker2 });
    /*gsap.from(".sculpture-item", {
      duration: 0.5,
      opacity: 0,
      scale: 0,
      y: 200,
      ease: "power1",
      stagger: 0.1
    });*/
  },
  methods: {

    changeState: function (step) {
        switch(step) {
            case 1: 
                document.getElementById("cotizacion-step-1").classList.toggle('hide');
                document.getElementById("cotizacion-step-2").classList.toggle('hide');            
            break
            case 2: 
                document.getElementById("cotizacion-step-2").classList.toggle('hide');
                document.getElementById("cotizacion-step-3").classList.toggle('hide');            
            break
            case 3: 
                document.getElementById("cotizacion-step-3").classList.toggle('hide');
                document.getElementById("cotizacion-step-4").classList.toggle('hide');            
            break
            case 4: 
                document.getElementById("cotizacion-step-4").classList.toggle('hide');
                document.getElementById("cotizacion-step-5").classList.toggle('hide');            
            break
            case 5: 
                document.getElementById("cotizacion-step-5").classList.toggle('hide');
                document.getElementById("cotizacion-step-6").classList.toggle('hide');            
            break
            case 6: 
                document.getElementById("cotizacion-step-5").classList.toggle('hide');
                document.getElementById("cotizacion-step-6").classList.toggle('hide');            
            break
            case 7: 
                document.getElementById("cotizacion-step-6").classList.toggle('hide');
                document.getElementById("cotizacion-step-7").classList.toggle('hide');            
            break
        }
    },
    toggleCompanySelected: function (company) {
        document.getElementById("company-"+company).classList.toggle("active");
        this.showSelectAuto = true;
    },
    toggleInfoWindow: function (marker, idx) {
        this.infoWindowPos = marker.position;
        this.infoContent = this.getInfoWindowContent(marker);
        

        //check if its the same marker that was selected if yes toggle
        if (this.currentMidx == idx) {
          this.infoWinOpen = !this.infoWinOpen;
        }
        //if different marker set infowindow to open and reset current marker index
        else {
          this.infoWinOpen = true;
          this.currentMidx = idx;
        }
      },
      getInfoWindowContent: function (marker) {
          //var logo = 'https://bulma.io/images/placeholders/96x96.png';
          var logo = 'http://paseguros.com.ar/img/logo_paseguros.02221369.svg';
          if (marker.logo != null) {
            logo = marker.logo;
          }

          var companies = '';
          if (marker.active_codes != null) {
              companies = 'Principales compañías<br>';
            for (let index = 0; index < marker.active_codes.length; index++) {
                const element = marker.active_codes[index];              
                companies += '<img src="'+element.company.url_logo+'" height="18px" style="margin: 0 1px;">';
            }
          }

          console.log(companies);
          console.log(marker);
          console.log(marker.active_codes);

              return (`<div class="card">
                        <div class="card-image">
                          <figure class="image is-4by3">
                            <img src="${logo}" alt="Placeholder image" width="100px">
                          </figure>
                        </div>
                        <div class="card-content">
                          <div class="media">
                            <div class="media-content">
                              <p class="title is-4">${marker.name}</p>
                            </div>
                          </div>
                          <div class="content">
                            ${marker.description}
                            <div class="companies-connected" style="padding: 20px 5px 0px";>${companies}</div>
                            <br>
                            <!--time datetime="2016-1-1">${marker.date_build}</time-->
                          </div>
                        </div>
                      </div>`);
      },
    setPlace(place) {
      this.currentPlace = place;
    },
    addMarker() {
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng()
        };
        this.markers.push({ position: marker });
        this.places.push(this.currentPlace);
        this.center = marker;
        this.currentPlace = null;
        this.zoom = 8
      }
    },
    geolocate: function() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng:  position.coords.longitude
        };
      });
    },
    proCloudGetLocalidades:function(event) {
        store.dispatch("procloud/fetchLocalidades", event.target.value).then(() => {
            console.log(this.procloud.localidades);
            this.localidades = this.procloud.localidades;
                
          
        });
    },
    infoautoGetBrand:function(){
        store.dispatch("infoauto/fetchBrands").then(() => {
            for (let index = 0; index < this.infoauto.brands.length; index++) {
                const element = this.infoauto.brands[index];
                if (element.logo_url != null) {
                    this.brands.push(element);
                }
                
            }
          console.log(this.brands);          
          
        });
    },
    infoautoGetModel:function(brandId){
        console.log(brandId);
        this.brandId = brandId;
        store.dispatch("infoauto/fetchModels", brandId).then((brandId) => {
            console.log(brandId);
            this.models = this.infoauto.models;
            this.changeState(2);          
        });
    },
    infoautoGetVersions:function(modelId){
        console.log(modelId);
        var Data = {
          brandId: this.brandId,
          modelId: modelId
        }
        store.dispatch("infoauto/fetchVersions", Data).then((brandId) => {
            console.log(brandId);
            this.versions = this.infoauto.versions;
            var maxYear = this.versions[0].to;
            var minYear = this.versions[0].since;
            var versions = this.versions;
            for (var i = 0; i < versions.length; i++) {
              if (versions[i].to > maxYear) {
                maxYear = versions[i].to;
              }
              if (versions[i].since < minYear) {
                minYear = versions[i].since;
              }
            }
            console.log(minYear);
            console.log(maxYear);
            for (var j = maxYear; j >= minYear; j--) {
              this.years.push(j);
              /*$("select#years").append(
                "<option value='" + i + "'>" + i + "</option>"
              );*/
            }
            console.log(this.years);
            this.changeState(3);          
        });
    },
    toggleVersionSelected:function(year) {
      console.log(year);
      this.year = year;
      this.versions.forEach(element => {
        console.log(element.name);
        console.log("Desde "+element.since);
        console.log("Hasta "+element.to);
        console.log("Año "+year);
        console.log("Mayor a Desde "+ element.since >= year);
        console.log("Menor a Hasta "+ element.to <= year);
        console.log("---");
        if (year >= element.since && year <= element.to) {
          this.versionsFiltered.push(element);
        }        
      });
      this.changeState(4);    
    },
    getPersonalData:function(version) {
      this.codia = version;
      this.changeState(5);
    },
    getQuotes:function(){
     
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth() + 1; //January is 0!

      var yyyy = today.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }

      var vigencia = dd + "/" + mm + "/" + yyyy;

      var localidad = document.getElementById("localidad");

      this.personalData = {
        infoauto: this.codia["codia"],
        ano: this.year,
        anio: this.year,
        codigo_postal: localidad.value.substr(6, 4),
        codigo_postal_san_cristobal: localidad.value,
        cuotas: 6,
        valor_gnc: 0,
        sumaaccesorios: 0,
        condicion_iva: "CF",
        persona: "F",
        sumaasegurada: 0,
        dni: document.getElementById("dni").value,
        fechadenacimiento: document.getElementById("fecha_nacimiento").value
          .replace(".", "/")
          .replace(".", "/"),
        sexo: "M",
        modo_de_pago: '02',
        uso: "01",
        cerokm: 0,
        ajuste_automatico: 2,
        vigencia: vigencia,
        provincia_id: document.getElementById("provincia").value
      };

      this.personalData.cliente = {
        nombre: document.getElementById("nombre").value,
        apellido: document.getElementById("apellido").value,
        fecha_nacimiento: document.getElementById("fecha_nacimiento").value
          .replace(".", "/")
          .replace(".", "/"),
        dni: document.getElementById("dni").value,
        mail: document.getElementById("email").value,
        direccion: " ",
        celular: document.getElementById("celular").value,
        cuit: "",
        tipo_dni: 96,
        sexo: 1,
        estado_civil: "1",
        prospecto: 1,
        modo_de_pago: '02',
        dir_numero: "",
        dir_piso: "",
        dir_departamento: "",
        dir_telefono: "",
        codigo_postal: localidad.value.substr(6, 4),
        provincia_id: document.getElementById("provincia").value,
        localidad_nombre: localidad.options[localidad.selectedIndex].text
      };

      var config_companies = [
                    {
                'id': 5,
                'productor': 'M13538',
                'tipo_documento': 'D',
                'rebaja': 10,
                'facturacion': 'M',
                'logo': 'https://app.procloud.com.ar/assets/companies/allianz.png',
                'coberturas' : {
                    "a" : {
                        'first': ['all'],
                        'replace' : ['']
                    },
                    "b" : {
                        'first': [''],
                        'replace' : ['']
                    },
                    "c" : {
                        'first': ['72'],
                        'replace' : ['58']
                    },
                    "d" : {
                        'first': ['73'],
                        'replace' : ['']
                    }
                }
            },{
                'id':6,
                'facturacion': 'M',
                'sancor_descuento_especial': '40',
                'intermediario_codigo': '213591',
                'intermediario_supervisor': '151643',
                'logo': 'https://app.procloud.com.ar/assets/companies/sancor.png',
                'intermediario_oficina': 1,
                'coberturas' : {
                    "a" : {
                        'first': ['1'],
                        'replace' : ['']
                    },
                    "b" : {
                        'first': [''],
                        'replace' : ['']
                    },
                    "c" : {
                        'first': ['12'],
                        'replace' : ['5']
                    },
                    "d" : {
                        'first': ['18'],
                        'replace' : ['']
                    }
                }
            },{
                'id':4,
                'facturacion': 'S',
                'comision': 22,
                'bonificacion': 20,
                'rebaja': 5,
                'logo': 'https://app.procloud.com.ar/assets/companies/hdi.png',
                'recargo': 0,
                'cuit': "20345213393",
                'productor': "78114",
                'coberturas' : {
                    "a" : {
                        'first': ['A'],
                        'replace' : ['']
                    },
                    "b" : {
                        'first': [''],
                        'replace' : ['']
                    },
                    "c" : {
                        'first': ['C1'],
                        'replace' : ['C']
                    },
                    "d" : {
                        'first': ['D4'],
                        'replace' : ['D3']
                    }
                }
            },{
                'id':2,
                'facturacion': 'S',
                'bonificacion': 14,
                'rebaja': 30,
                'recargo': 0,
                'logo': 'https://app.procloud.com.ar/assets/companies/sancristobal.png',
                'cuit': "20345213393",
                'productor': "05-003921",
                'coberturas' : {
                    "a" : {
                        'first': ['CA7_A'],
                        'replace' : ['']
                    },
                    "b" : {
                        'first': ['CA7_B'],
                        'replace' : ['']
                    },
                    "c" : {
                        'first': ['CA7_CPlus'],
                        'replace' : ['CA7_CM']
                    },
                    "d" : {
                        'first': ['CA7_D15'],
                        'replace' : ['CA7_D14']
                    }
                }
            },{
                    'id': 7,
                    'productor': '25229',
                    'bonificacion': '-5',
                    'plan': 120330,
                    'logo': 'https://app.procloud.com.ar/assets/companies/federacionpatronal.png',
                    'coberturas' : {
                        "a" : {
                            'first': ['all'],
                            'replace' : ['']
                        },
                        "b" : {
                            'first': ['B1'],
                            'replace' : ['B']
                        },
                        "c" : {
                            'first': ['C1'],
                            'replace' : ['C']
                        },
                        "d" : {
                            'first': ['TD3'],
                            'replace' : ['TD1']
                        }
                    }
                },{
                    'id': 18,
                    'productor': '63487',
                    'facturacion': "M",
                    'comision': 0.05,
                    'descuento': 10,
                    'riesgo': 1,
                    'password': 'Huerta20',
                    'logo': 'https://app.procloud.com.ar/assets/companies/providencia.png',
                    'coberturas' : {
                        "a" : {
                            'first': ['200'],
                            'replace' : ['']
                        },
                        "b" : {
                            'first': ['201'],
                            'replace' : ['239']
                        },
                        "c" : {
                            'first': ['213'],
                            'replace' : ['212']
                        },
                        "d" : {
                            'first': ['258'],
                            'replace' : ['']
                        }
                    }
                }
            ];

        for (var i = 0; i < config_companies.length; i++) {
              this.personalData.companies = config_companies[i];
              /*$(".coberturas-loading h4").append(
                '<img src="' +
                  CONFIG_COMPANIES[i].logo +
                  '" height="30" id="logo-company-' +
                  CONFIG_COMPANIES[i].id +
                  '" style="height: 25px;margin: 0 2.5px;display:inline-block;">'
              );
              console.log(datosCotizacion.companies);*/
              store.dispatch("procloud/fetchQuotes", this.personalData).then(() => {
                  if (this.showQuotes == false && this.procloud.quotes[0].code == 200) {
                    this.quotes.push(this.procloud.quotes[0]);
                    this.showQuotes = true;
                    this.changeState(7);    
                  }else if (this.showQuotes == true) {
                    this.quotes.push(this.procloud.quotes[0]);
                  }

                  console.log(this.quotes);
                  
              });
              //$.proCloud.getSimpleQuote(datosCotizacion);
            }
            
      //datosCotizacion = data;
      //datosCotizacion.companies = data;

      console.log(this.personalData);
       
    }
  }
};
</script>


<style lang="scss" scoped>
.company-quote-item {
    vertical-align: top;
    max-width: 200px;
    min-width: 140px;
  .quote-item {
    border: 2px solid #68e291;
    margin: 5px 0;
    padding: 10px;
    border-radius: 10px;
    transition: all .3s ease-in-out;
    .cobertura-costo {
        color: #63dba3;
        font-weight: 900;
        font-size: 1.5rem;
        margin: 5px 0 ;
    }
    .cobertura-nombre {
      font-size: 0.5rem;
    }
    .cobertura-familia {
      border-radius: 5px;
      color: white;
      padding: 2px 0;
      margin-bottom: 5px;
      background: #e26868;
      &.cobertura-familia-A {
        background: #e26868;
      }
      &.cobertura-familia-B {
        background: #9868e2;
      }
      &.cobertura-familia-C {
        background: #68e291;
      }
      &.cobertura-familia-D {
        background: #6892e2;
      }
    }
  &:hover {
    background: #68e291;
    .cobertura-costo {
        color: white;
        font-weight: 900;
        font-size: 1.5rem;
    }
    .cobertura-nombre {
      font-size: 0.5rem;
    }
    .cobertura-familia {
        border-radius: 5px;
        color: #63dba3;
        background: white;

        &.cobertura-familia-A,
        &.cobertura-familia-B ,
        &.cobertura-familia-C ,
        &.cobertura-familia-D {
          background: white;
        }
      }
    }
  }
  @media all and (max-width: 768px) {
    width: 35%;
    max-width: 200px;
  }
  @media all and (max-width: 480px) {
    width: 100%;
    max-width: 220px;
  }
}

.filter {
  .filter-input {
    width: 80%;
    max-width: 460px;
    background: none;
    border: 0;
    border-bottom: 2px solid #63dba3;
    font-size: 1.4rem;
    padding: 10px; 
    color: #63dba3;
    &:focus {
      outline: none;
      border-bottom: 2px solid #403dd6;
    }
  }
}

.hide {
    transition: all .3s ease-in-out;
    margin-top: 5px;
    opacity: 0;
    display: none;
}

.companies-connected {
    margin: 5px;
    img {
        margin: 0 2px;
    }
}

.productores-section {
    width: 100%;
    margin: 0px 0%;
    padding: 30px 0 0; 
    overflow: hidden;
    background: #E9FEF7;
    background: #d5ecff;

    #productores-map {
        margin-top: 30px;
    }

    .seleccion-marca-auto {
        margin: 15px 0;
        padding: 15px;
        border-radius: 15px;
        color: white;
        background: #00E190;
        display: block;
        width: 90%;
        font-weight: 500;
        text-transform: uppercase;
        margin: 20px auto;
        border: 2px solid #E9FEF7;
        cursor: pointer;
        
        transition: all .2s ease-in-out;
        &:hover {
            color: #00E190;
            background: white;
            border: 2px solid #00E190;
        }

    }

    .select-item {
        max-width: 180px;
        width: 15%;
        min-width: 100px;
        margin: 15px;
        padding: 20px;
        background: white;
        border-radius: 20px;
        display: inline-block;
        transition: all .2s ease-in-out;
        border: 5px solid #ffffff;
        cursor: pointer;
        &:hover ,
        &.active {
            box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.2);
        }
        &.active {
            border: 5px solid #00E190;
        }
    }

    .model-item,
    .version-item,
    .year-item {
      padding: 10px;
    }

    .brand-item {
        vertical-align: middle;
        img {
            max-width: 100%;
            max-height: 80px;
            height: auto; 
        }
    }
    .company-item {
        img {
            width: 100%;
        }
    }

    .productores-info {
        width: 70%;
        margin: 0 auto 30px;
        text-align: center;
        h2 {
            font-size: 1.8rem;
        }
        strong {
            font-family: Montserrat;    
            font-weight: 700;
        }
        p {
            font-family: Montserrat;
            font-size: 1.1rem;
            color: #FFFFFF;
            line-height: 2;
            color: #13254A;
            letter-spacing: -0.15px;
        }
    }
}


.form-group {
  width: 70%;
  max-width: 900px;
  margin: 0 auto;
  .form-item {

    vertical-align: top;
    display: inline-block;
    text-align: left;
    margin-right: 10px;
    label {
      text-align: left;
      display: block;
      font-size: 0.8rem;
      font-weight: 400;
      margin-bottom: 5px;
    }
    input {
      color: #333333;
      text-align: left;
      min-width: 100px;
      padding: 20px;
      background: white;
      border-radius: 20px;
      display: inline-block;
      transition: all .2s ease-in-out;
      border: 5px solid #ffffff;
      cursor: pointer;
      &:focus {
        border: 5px solid #00E190;
        border-radius: 20px;
        outline: none;
      }
    }
    select {
      height: 50px;
      min-width: 100px;
      background: white;
      color: #333333;
      border-radius: 20px;
      display: inline-block;
      transition: all .2s ease-in-out;
      border: 5px solid #ffffff;
      cursor: pointer;
      &:focus {
        border: 5px solid #00E190;
        border-radius: 20px;
        outline: none;
      }
    }
  }
}

</style>
